import { ChainId, chains, chainsSetup } from '@sdk/constants'
import { CHAIN_IDS } from 'utils/wagmi'

export const SUPPORT_FARMS = chains.filter((chain) => chain.features.includes('farms')).map((chain) => chain.id)
export const SUPPORT_FARMS_V3 = chains.filter((chain) => chain.features.includes('farmsV3')).map((chain) => chain.id)
export const SUPPORT_ONLY_BSC = [ChainId.BSC]

export const SUPPORT_ANY = CHAIN_IDS

export const SUPPORT_SWAP = chains.filter((chain) => chain.features.includes('swap')).map((chain) => chain.id)
export const SUPPORT_SWAP_V3 = chains.filter((chain) => chain.features.includes('swapV3')).map((chain) => chain.id)
export const SUPPORT_STAKING = chains.filter((chain) => chain.features.includes('staking')).map((chain) => chain.id)
export const SUPPORT_INFO = chains.filter((chain) => chain.features.includes('info')).map((chain) => chain.id)
export const SUPPORT_BRIDGE = chainsSetup.filter((chain) => chain.features.includes('bridge')).map((chain) => chain.id)
export const SUPPORT_CROSS_SWAP = chainsSetup
  .filter((chain) => chain.features.includes('cross-swap'))
  .map((chain) => chain.id as ChainId)

export const SUPPORT_LAUNCHPAD = chains.filter((chain) => chain.features.includes('launchpad')).map((chain) => chain.id)
export const SUPPORT_ZAP = []
export const SUPPORT_BUY_CRYPTO = []
export const LIQUID_STAKING_SUPPORTED_CHAINS = []
export const FIXED_STAKING_SUPPORTED_CHAINS = []

export const V3_MIGRATION_SUPPORTED_CHAINS = []

export const SUPPORT_CAKE_STAKING = []
